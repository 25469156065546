<template>
	<div class="section-15">
		<div class="container-section">
			<div class="content-section">
				<div class="content-left">
					<div class="center-on-div">
						<h2 class="section-title">Pe ce se focalizează oamenii tăi azi?</h2>
						<div class="section-description">În fiecare zi, colegii tăi își vor stabili promisiunea zilei în TBF Digital. Mai precis, răspund la întrebarea: Care este cel mai important lucru pe care îl voi realiza azi?</div>
					</div>
				</div>
				<div class="content-right">
					<div class="image-animated">
						<svg-section-15 />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import SvgSection15 from '../../assets/landing/SvgSection15'

	export default {
		components: {
			SvgSection15
		},
		mounted() {
			
		}
	}
</script>